import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GetContextMenuItemsParams, GridOptions, MenuItemDef } from "ag-grid-community";
import { SavedReadingPhrase } from "spinel";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
   StyledTab,
   StyledTabs,
   StyledClassFunctionList,
   StyledContextButtonWrapper,
   StyledFixedBack,
   StyledFixedForward,
   StyledFlexChild,
   StyledFunctionList,
   StyledOption,
   StyledOrder,
   StyledSelect,
   StyledSelectWrapper,
   StyledTemplateList,
   StyledPreformWrapper,
} from "./styles/SettingMacroStyles";
import i18n from "../../../../utils/i18n-utils";
import { Macro, MacroFunction, ShortcutsSetting, WorklistFunction } from "./types/SettingShortcutTypes";
import { CustomTooltip } from "../../../../../public/resource/js/customTooltip";
import { Preset } from "./types/SettingMacroTypes";
import {
   StyledContext,
   StyledContextText,
   StyledContextWrapper,
   StyledGridWrapper,
   StyledPageContext,
   StyledSettingContainer,
   StyledWrapper,
} from "./styles/SettomgStyles";
import TextTypeField from "../../../input/textfield/TextTypeField";
import { StyledTextFontShortcut } from "./styles/SettingShortcutStyles";
import { useToastDispatch } from "../../../../context/ToastContext";
import UserConfigAPI from "../../../../api/UserConfigAPI";
import store from "../../../../redux/store";
import { QueryKey } from "../../../../redux/reducers/query";
import { StyledHealthhubIcon } from "../../styles/CommonDialogStyles";
import OkBtn from "../../../button/OkBtn";
import CancelBtn from "../../../button/CancelBtn";
import { ReadingPhraseList } from "../../../report/readingPhraseList/ReadingPhraseList";
import SnowPhraseAPI from "../../../../api/SnowPhraseAPI";

export default function SettingMacro() {
   const toastDispatch = useToastDispatch();
   const element = useRef<HTMLDivElement>(null);
   const [id, setId] = useState<string>("");
   const [shortcut, setShortcut] = useState<string>();
   const [title, setTitle] = useState<string>();
   const [seqNo, setSeqNo] = useState<number>();
   const [_shortcut, set_Shortcut] = useState<ShortcutsSetting>({
      id: "",
      filmboxFunction: [],
      macroFunction: [],
      userPreferences: [],
      worklistFunction: [],
   });
   const [orderList, setOrderList] = useState<Array<Macro>>([]);
   const [expanded, setExpanded] = useState<"templateList" | "readingPhraseList">("templateList");
   const [preformList, setPreformList] = useState<Array<Preset>>([]);
   // const [_preformList, set_PreformList] = useState<Array<Preset>>([]);
   const [preformSelectedList, setPreformSelectedList] = useState<Array<string>>([]);
   const [functionList, setFunctionList] = useState<ShortcutsSetting>();
   // const [_functionList, set_FunctionList] = useState<Array<object>>([]);
   const [isValidateShortcut, setIsValidateShortcut] = useState<boolean>(false);
   const [functionSelectedList, setFunctionSelectedList] = useState<Array<string>>([]);
   const [selectedList, setSelectedList] = useState<Array<string>>([]);
   const [worklistFunction, setWorklistFunction] = useState<Array<WorklistFunction>>([]);
   const [rowData, setRowData] = useState<Array<any>>([]);
   const [selectedRow, setSelectedRow] = useState<any>();
   const columnDefs: ColDef[] = useMemo(() => [
      { headerName: i18n("label.title"), field: "title", width: 120 },
      { headerName: i18n("label.macroFunctionList"), field: "functionNameList", width: 330 },
      { headerName: i18n("label.shortcut"), field: "shortcut", width: 100 },
   ], []);

   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         sortable: true,
         suppressMenu: true, // 메뉴 막기
      },
      rowDragManaged: true,
      components: {
         customTooltip: CustomTooltip,
      },
      tooltipShowDelay: 0,
      animateRows: true,
      overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${i18n("label.noRecordsFound")}</span>`,
      rowSelection: "single",
      onRowSelected: (e) => {
         setSelectedRow(e.node);
      },
      onRowDoubleClicked: (e) => {
         const { shortcut, title, seqNo } = e.data;
         setIsValidateShortcut(false);
         setShortcut(shortcut);
         setTitle(title);
         setSeqNo(seqNo);

         const functionSelected: Array<string> = [];
         const preformSelected: Array<string> = [];
         const macroSelected: Array<string> = [];
         UserConfigAPI.getUserShortcutByUserId().then(async (resolve: ShortcutsSetting | any) => {
            for (let i = 0; i < resolve.macroFunction.length; i++) {
               if (seqNo === resolve.macroFunction[i].seqNo) {
                  for (let j = 0; j < resolve.macroFunction[i].functionList.length; j++) {
                     const ms = resolve.macroFunction[i].functionList[j].functionName;
                     if (resolve.macroFunction[i].functionList[j].type === "preform") {
                        macroSelected.push(`@${ms}`);
                        preformSelected.push(ms);
                     } else if (resolve.macroFunction[i].functionList[j].type === "function") {
                        // const ms = resolve.macroFunction[i].functionList[j].displayedName;
                        macroSelected.push(`#${ms}`);
                        functionSelected.push(ms);
                     } else {
                        const id = resolve.macroFunction[i].functionList[j].functionName;
                        try {
                           // eslint-disable-next-line no-await-in-loop
                           const phrase = await SnowPhraseAPI.getReadingPhrase(id);
                           macroSelected.push(`$${phrase.title}|${id}`);
                        } catch (err) {
                           console.error(err);
                        }
                     }
                  }
               }
            }

            setId(resolve.id);
            setPreformSelectedList(preformSelected);
            setFunctionSelectedList(functionSelected);
            setSelectedList(macroSelected);
         });
      },
   });

   /*
   * https://www.ag-grid.com/react-data-grid/context-menu/
   * */
   const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) : (string | MenuItemDef)[] => {
      if (!params.node) return [];
      params.node.setSelected(true, true);
      const result = [
         {
            name: i18n("label.delete"),
            action() {
               UserConfigAPI.deleteMacroShortcuts(params.node.data.seqNo).then((result) => {
                  if (result) {
                     const msg = i18n("msg.settingDialog.macro.delete.success");
                     openToast(msg, false);
                     set_Shortcut(result);
                     setFunctionList(result);
                     clearMacroContext();
                  }
                  else {
                     const msg = i18n("msg.settingDialog.macro.delete.fail");
                     openToast(msg, true);
                  }
               });
            },
         },
      ];
      return result;
   }, []);

   useEffect(() => {
      setFunctionName();
   }, [selectedList]);

   useEffect(() => {
      if (functionSelectedList && functionSelectedList.length > 0) {
         const newFunctionList = worklistFunction;
         for (let i = 0; i < functionSelectedList.length; i++) {
            for (let j = 0; j < worklistFunction.length; j++) {
               if (functionSelectedList[i] === newFunctionList[j].functionName) {
                  newFunctionList[j].selected = true;
                  break;
               }
            }
         }

         setWorklistFunction(newFunctionList);
      } else {
         const newFunctionList = worklistFunction;
         for (let i = 0; i < worklistFunction.length; i++) {
            newFunctionList[i].selected = false;
         }
         setWorklistFunction(newFunctionList);
      }
   }, [functionSelectedList]);

   useEffect(() => {
      if (preformList) {
         const newPreformList = preformList;
         for (let i = 0; i < preformSelectedList.length; i++) {
            for (let j = 0; j < preformList.length; j++) {
               if (preformSelectedList[i] === newPreformList[j].title) {
                  newPreformList[j].selected = true;
                  break;
               }
            }
         }

         setPreformList(newPreformList);
      }
   }, [preformSelectedList]);

   useEffect(() => {
      UserConfigAPI.getPreformByUserId().then((result) => {
         if (!result) {
            const msg = i18n("msg.settingDialog.macro.template.fail");
            openToast(msg, true);
            return;
         }
         setPreformList(result);
      });

      UserConfigAPI.getUserShortcutByUserId().then((resolve: ShortcutsSetting | any) => {
         set_Shortcut(resolve);
         setFunctionList(resolve);
      });
   }, []);

   useEffect(() => {
      if (functionList?.macroFunction) {
         const { macroFunction } = functionList;
         const newMacroFunction: Array<MacroFunction> = [];
         macroFunction.forEach((macroFunction) => {
            const titleList: Array<string> = [];
            macroFunction.functionList.forEach((macro) => {
               titleList.push(macro.functionName);
            });
            newMacroFunction.push({
               ...macroFunction,
               functionNameList: titleList,
            });
         });

         set_Shortcut(prevState => ({
            ...prevState,
            macroFunction: newMacroFunction,
         }));
         setRowData(newMacroFunction);
      }
   }, [functionList]);

   useEffect(() => {
      if (_shortcut) {
         const { worklistFunction } = _shortcut;
         if (worklistFunction) setWorklistFunction(worklistFunction);
         if (_shortcut.id) store.dispatch({ type: QueryKey.GET_SHORTCUT_LIST, payload: { ..._shortcut } }); // create, update, delete 후 setting
      }
   }, [_shortcut]);

   function onClickPreform(title: string) {
      const preformTitle = `@${title}`;
      if (!selectedList.includes(preformTitle)) {
         setSelectedList(current => [...current, preformTitle]);
         setPreformSelectedList(current => [...current, title]);
      }
      else {
         setSelectedList(current => current.filter(item => item !== preformTitle));
         setPreformSelectedList(current => current.filter(item => item !== title));
      }

      setFunctionName();
   }

   function onSelectReadingPhrase(phrase: SavedReadingPhrase) {
      const readingPhraseTitle = `$${phrase.title}|${phrase.phraseId}`;
      const alreadyIncluded = selectedList.filter(selected => selected.startsWith("$"))
         .find(selectedReadingPhrase => selectedReadingPhrase.split("|").at(-1) === phrase.phraseId);
      if (!alreadyIncluded) {
         setSelectedList(current => [...current, readingPhraseTitle]);
      } else {
         setSelectedList(current => current.filter(selected => (
            !selected.startsWith("$") || selected.split("|").at(-1) !== phrase.phraseId
         )));
      }

      setFunctionName();
   }

   function onClickFunction(title: string) {
      const functionTitle = `#${title}`;
      if (!selectedList.includes(functionTitle)) {
         setSelectedList(current => [...current, functionTitle]);
         setFunctionSelectedList(current => [...current, title]);
      }
      else {
         setSelectedList(current => current.filter(item => item !== functionTitle));
         setFunctionSelectedList(current => current.filter(item => item !== title));
      }

      setFunctionName();
   }

   function handleTitleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      setTitle(event.target.value);
   }

   function handleShortcutChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      setShortcut("");
      const { value } = event.target;
      const regex = /[a-z\s]/;
      if (regex.test(value)) {
         setShortcut(value);
         setIsValidateShortcut(false);
      } else setIsValidateShortcut(true);
   }

   function setDefaultShortcut() {
      // _shortcut = defaultShortcutSetting();
      // set_Shortcut(defaultShortcutSetting);
      // const functionShortcut = {};
      // const worklistShortcut = _shortcut.worklistFunction;
      // const filmboxShortcut = _shortcut.filmboxFunction;
      // functionShortcut.worklistFunction = worklistShortcut;
      // functionShortcut.filmboxFunction = filmboxShortcut;
      // setFunctionList(functionShortcut);
      // rowData = [];
   }

   function clearMacroContext() {
      setSeqNo(undefined);
      setPreformSelectedList([]);
      setFunctionSelectedList([]);
      setSelectedList([]);
      setOrderList([]);
      setTitle("");
      setShortcut("");
      selectedRow?.setSelected(false, false);
   }

   function setFunctionName() {
      const tempOrderList: Array<Macro> = [];
      for (let i = 0; i < selectedList.length; i++) {
         if (selectedList[i].startsWith("#")) {
            // set function list
            let flag = false;
            for (let j = 0; j < _shortcut.worklistFunction.length; j++) {
               if (_shortcut.worklistFunction[j].functionName === selectedList[i].substring(1)) {
                  flag = true;
                  const macro: Macro = { functionName: "", seqNo: 0, type: "" };
                  macro.functionName = _shortcut.worklistFunction[j].functionName;
                  macro.displayedName = `#${_shortcut.worklistFunction[j].displayedName.replace("*", "")}`;
                  tempOrderList.push(macro);
                  break;
               }
            }
            if (!flag) {
               // if not having workList
               for (let j = 0; j < _shortcut.filmboxFunction.length; j++) {
                  if (_shortcut.filmboxFunction[j].functionName === selectedList[i].substring(1)) {
                     flag = true;
                     const macro: Macro = { functionName: "", seqNo: 0, type: "" };
                     macro.functionName = _shortcut.filmboxFunction[j].functionName;
                     macro.displayedName = `#${_shortcut.filmboxFunction[j].displayedName.replace("*", "")}`;
                     tempOrderList.push(macro);
                     break;
                  }
               }
            }
         } else if (selectedList[i].startsWith("@")) {
            // set preform list
            const macro: Macro = { functionName: "", seqNo: 0, type: "" };
            macro.functionName = selectedList[i].substring(1);
            macro.displayedName = selectedList[i];
            tempOrderList.push(macro);
         } else {
            // set reading phrase list
            const parsedName = selectedList[i].split("|");
            const name = parsedName.slice(0, -1).join("|");
            const id = parsedName.at(-1) as string;
            const macro: Macro = { functionName: "", seqNo: 0, type: "" };
            macro.functionName = id;
            macro.displayedName = name;
            tempOrderList.push(macro);
         }
      }

      // set order list
      setOrderList(tempOrderList);
   }

   function backFilter() {
      element.current?.scrollTo(element.current?.scrollLeft - 60, 0);
   }

   function forwardFilter() {
      element.current?.scrollTo(element.current.scrollLeft + 60, 0);
   }

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function saveValidationMacro() {
      if (!title) {
         const msg = i18n("msg.settingDialog.macro.save.enterTitle");
         openToast(msg, true);
      } else if (orderList.length === 0) {
         const msg = i18n("msg.settingDialog.macro.save.selectFunction");
         openToast(msg, true);
      } else if (!shortcut) {
         const msg = i18n("msg.settingDialog.macro.save.enterShortcut");
         openToast(msg, true);
      } else {
         const macroList = [];
         for (let i = 0; i < orderList.length; i++) {
            const macroFunction = {
               seqNo: i,
               functionName : orderList[i].functionName,
               displayName : orderList[i].displayedName,
               // eslint-disable-next-line no-nested-ternary
               type: selectedList[i].startsWith("#") ? "function" : selectedList[i].startsWith("@") ? "preform" : "snowphrase",
            };

            macroList.push(macroFunction);
         }
         const macro = {
            seqNo: -1,
            title,
            functionList: macroList,
            shortcut,
         };
         if (seqNo) {
            let flag_ds = false;
            for (let i = 0; i < _shortcut.macroFunction.length; i++) {
               if (_shortcut.macroFunction[i].shortcut === shortcut && seqNo !== selectedRow.data.seqNo) {
                  flag_ds = true;
                  break;
               }
            }
            if (flag_ds) {
               console.log("Macro Shortcut you entered is already existed");
               const msg = i18n("msg.settingDialog.macro.save.exist");
               openToast(msg, true);
               return false;
            }

            macro.seqNo = seqNo;
            UserConfigAPI.updateMacroShortcuts(macro, id).then((result) => {
               if (result) {
                  set_Shortcut(result);
                  setFunctionList(result);
                  clearMacroContext();
                  const msg = i18n("msg.settingDialog.macro.save.updateSuccess");
                  openToast(msg, false);
               }
               else {
                  const msg = i18n("msg.settingDialog.macro.save.updateFail");
                  openToast(msg, true);
               }
            });

            return;
         } if (_shortcut.macroFunction) {
            if (_shortcut.macroFunction.length !== 0) {
               let flag_ds = false;
               for (let i = 0; i < _shortcut.macroFunction.length; i++) {
                  if (_shortcut.macroFunction[i].shortcut === shortcut) {
                     flag_ds = true;
                     break;
                  }
               }
               if (flag_ds) {
                  console.log("Macro Shortcut you entered is already existed");
                  const msg = i18n("msg.settingDialog.macro.save.exist");
                  openToast(msg, true);
                  return false;
               }
            }
         }

         UserConfigAPI.createMacroShortcuts(macro).then((result) => {
            if (result) {
               const msg = i18n("msg.settingDialog.macro.save.createSuccess");
               openToast(msg, false);
               set_Shortcut(result);
               setFunctionList(result);
               clearMacroContext();
            }
            else {
               const msg = i18n("msg.settingDialog.macro.save.createFail");
               openToast(msg, true);
            }
         });
      }
   }

   return (
      <>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.css"/>
         <link rel="stylesheet" href="/resource/style/theme/healthhub-default.css"/>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css"/>
         <StyledSettingContainer>
            <StyledWrapper>
               <StyledPageContext>
                  <StyledContextWrapper>
                     <StyledContext>
                        <input type="hidden" id="seqNo" value={seqNo || ""}/>
                        <StyledContext style={{ marginBottom: "5px" }}>
                           <StyledContextText>{i18n("label.title")}</StyledContextText>
                           <TextTypeField
                              value={title || ""}
                              onChange={handleTitleChange}
                              placeholder="Enter Title"
                              variant="standard"
                              shrink
                              style={{ width: "365px" }}
                           />
                        </StyledContext>
                     </StyledContext>
                     <StyledSelectWrapper>
                        <StyledPreformWrapper>
                           <Accordion
                              expanded={expanded === "templateList"}
                              onChange={(e, isExpanded) => setExpanded(isExpanded ? "templateList" : "readingPhraseList")}
                           >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                 {i18n("label.templateList")}
                              </AccordionSummary>
                              <AccordionDetails>
                                 <StyledTemplateList>
                                    <StyledContextText style={{ marginTop: 0 }}>@ {i18n("label.templateList")}</StyledContextText>
                                    <StyledSelect
                                       multiple
                                       value={preformSelectedList}
                                       onChange={() => console.log(``)}
                                    >
                                       {preformList && preformList.map((preform, index) => (
                                          <StyledOption key={index} value={preform.title} onClick={() => onClickPreform(preform.title)}>{preform.title}</StyledOption>
                                       ))}
                                    </StyledSelect>
                                 </StyledTemplateList>
                              </AccordionDetails>
                           </Accordion>
                           <Accordion
                              expanded={expanded === "readingPhraseList"}
                              onChange={(e, isExpanded) => setExpanded(isExpanded ? "readingPhraseList" : "templateList")}
                           >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                 {i18n("label.readingPhraseList")}
                              </AccordionSummary>
                              <AccordionDetails style={{ height: "300px" }}>
                                 <ReadingPhraseList
                                    onSelectReadingPhrase={(phrase) => {
                                       onSelectReadingPhrase(phrase);
                                    }}
                                 />
                              </AccordionDetails>
                           </Accordion>
                        </StyledPreformWrapper>
                        <StyledFunctionList>
                           <StyledContextText style={{ marginTop: 0 }}># {i18n("label.functionList")}</StyledContextText>
                           <StyledSelect
                              multiple
                              value={functionSelectedList}
                              onChange={() => console.log(``)}
                           >
                              {
                                 _shortcut && _shortcut.worklistFunction.map((worklistFunction, index) => (
                                    <StyledOption
                                       key={index}
                                       value={worklistFunction.functionName}
                                       onClick={() => onClickFunction(worklistFunction.functionName)}
                                    >{worklistFunction.displayedName}</StyledOption>
                                 ))
                              }
                           </StyledSelect>
                        </StyledFunctionList>
                     </StyledSelectWrapper>
                     <StyledContext>
                        <StyledContextText>{i18n("label.order")}</StyledContextText>
                        <StyledClassFunctionList>
                           <StyledFixedBack>
                              <StyledHealthhubIcon icon={"filter-left"} onClick={backFilter} />
                           </StyledFixedBack>
                           <StyledFlexChild ref={element}>
                              {orderList && orderList.map((order, index) => (
                                 <StyledOrder key={index}>{order.displayedName}</StyledOrder>
                              ))}
                           </StyledFlexChild>
                           <StyledFixedForward>
                              <StyledHealthhubIcon icon={"filter-right"} onClick={forwardFilter} />
                           </StyledFixedForward>
                        </StyledClassFunctionList>
                     </StyledContext>
                     <StyledContext>
                        <StyledContextText>{i18n("label.shortcut")}</StyledContextText>
                        <TextTypeField
                           value={shortcut || ""}
                           onChange={handleShortcutChange}
                           inputProps={{ style: { fontSize: "16px", textAlign: "center", alignSelf: "center", width: "250px" } }}
                           error={isValidateShortcut}
                           variant="standard"
                           placeholder="Enter Text"
                           shrink
                        />
                        <StyledTextFontShortcut>+ {i18n("label.ctrl")} <br/> + {i18n("label.enterBackslash")}</StyledTextFontShortcut>
                     </StyledContext>
                  </StyledContextWrapper>
               </StyledPageContext>

               <StyledContextButtonWrapper>
                  <OkBtn onClick={saveValidationMacro} text={i18n("button.save")}/>
                  <CancelBtn onClick={clearMacroContext} text={i18n("button.clear")}/>
                  {/* <StyledKindButton kind={"ok"} onClick={saveValidationMacro}>{i18n("button.save")}</StyledKindButton> */}
                  {/* <StyledKindButton onClick={clearMacroContext}>{i18n("button.clear")}</StyledKindButton> */}
               </StyledContextButtonWrapper>
               <StyledGridWrapper className={"ag-theme-balham-dark"}>
                  <AgGridReact
                     rowData={rowData}
                     columnDefs={columnDefs}
                     gridOptions={gridOptions}
                     getContextMenuItems={getContextMenuItems}
                  />
               </StyledGridWrapper>
            </StyledWrapper>
         </StyledSettingContainer>
      </>
   );
}
