import styled from "@emotion/styled";
import { Tab, Tabs } from "@mui/material";

export const StyledTab = styled(Tab)`
    color: #aaaaaa;
`;

export const StyledTabs = styled(Tabs)`
    & .MuiTabs-flexContainer {
        justify-content: center;
    }
`;

/* .wrap-selected */
export const StyledSelectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 3px;
    padding-bottom: 3px;
    height: 100%;
    margin-bottom: 5px;
`;

export const StyledPreformWrapper = styled.div`
    display: flex,
    flex-direction: column,
    width: 170px;
`;

/* .prefrom-list */
export const StyledTemplateList = styled.div`
`;

export const StyledFunctionList = styled.div`
    width: 190px;
`;

/* .class-functionList */
export const StyledClassFunctionList = styled.div`
    overflow: auto;
    display: flex;
    width: 405px;
`;

/* .fixed-back */
export const StyledFixedBack = styled.div`
    align-self: center;
`;

/* .flex-child */
export const StyledFlexChild = styled.div`
    flex: 1;
    white-space: nowrap;
    overflow-x: hidden;
    align-self: center;
    display: inherit;
    height: 30px;
`;

export const StyledOrder = styled.div`
    font-size: 10pt;
    padding: 5px;
`;

/* .fixed-Forward */
export const StyledFixedForward = styled.div`
    align-self: center;
`;

/* .contextBtn */
export const StyledContextButtonWrapper = styled.div`
    text-align: right;
    padding: 5px;
    margin: 5px;
    display: block;
`;

export const StyledSelect = styled.select`
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #262934;
    border: solid 0;
    font-size: 10pt;
    height: 205px;
    color: #fff;
    &:focus {
        outline: none;
    }
`;

export const StyledOption = styled.option`
    width: 100%;

    &:checked {
        outline: none;
        color: #ffffff;
        background: #0087cb !important;
    }
`;
