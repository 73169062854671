import forge from "node-forge";

/* eslint-disable no-undef */
const spinellaPubKey = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA38b0GRe1emUHcbyIBtiz
CQeQ1t1mP3Ee27luEhfUhBtXHrQzX45KjkGRK3pbhfz9L3+6a0iUmkY6O4LLMa2l
SLJugW/3s6Sk4Pg/uVCVbQFYqrfilCTollR/QhaXoVScQ6OxQipUCDPdOqXHfUu4
B4aogG0tZxudP6HWFMluoU11+qGuNkFA3YgZHxu6A2ELG65io1OR0duzN5V+xxgB
qK+uvxsa3W/R/ujqU+wArHEYh4Ffm92Iluka+1XC1QqRCvgGSw241TNoIiVGGsj2
L8+uRBChWQUwABVD7DdAeOShYmnb07dmeTX6QofHQMRcnwjD4Nelet5I7GGJe/HK
tQIDAQAB
-----END PUBLIC KEY-----`);
// 클라에 두는 건 미친 짓
const ourPrivKey = forge.pki.privateKeyFromPem(atob(`LS0tLS1CRUdJTiBQUklWQVRFIEtFWS0tLS0tCk1
JSUV2QUlCQURBTkJna3Foa2lHOXcwQkFRRUZBQVNDQktZd2dnU2lBZ0VBQW9JQkFRQ3JSS2EzMUwwVmVoam4KR0h1R2p
nRUlEMFgzTkFlOGRibStkenQwQ000bkZkMFlaaC9aVCtPSTRxam5HVWR6WHFGY2ZjWFM5emV4RkJxVQpGcUlLaUFCNW1
RRzBqeC9TMHVmQ0VsSE1nL1Ivb3FGczFPLzVBR0d0SmJ3Vk9DS0JVd1lvd3I4NmxRaXNJN0xsCldxV0JqTVRCRkxRS0F
4WjlnSVdKd3RRTGxOR3E0d1NHQ0YrdUtzNkVLN25YREtMVkxZTTZrdzcxcGtOazMvdTUKM1p5aW5ZU3RaaTVHdHVZNnR
tY0xscjVnb1ZZT21BWVF4UW96L1AzaEhNY0ZnK3BjUXcyM3NKMWU5UjRmRlF3ZQpjVWJBUDY4Nmc4Sm9tYVJjTEttc1R
1aVRRcFArUVhyaXRoNWJaUUtRbUJDV01ORFQ1cnV6VFE5NjFpUXRwTWk2CkErUmpOcm9IQWdNQkFBRUNnZ0VBYi9lUXV
1dlV0ZDY5dXc4eGFuVVp6WWdXcUpjV1c2bm5SZEt6UnBlZkZzUmYKWjkwVUEwRU1NdWhqbmJuVUVXT1RRR0ZsWEJlTkR
ESmxjZDBlRUo1N0dRUGpyb1JIUE0vdS9keDJKNnM0dzl5eApoZkhyOUxEQUtxOVp0OUdjRlVvTzQ4VDF1Ym5IRXFyQUF
OdHpDaS9hVFpSWVk1ZERNOGM3QmlZZDlIaFZYazY3CnlQUER1ZnVhRVF6Z3IvRVVlbklOTWdBVFdMQXUycS9JUGdGbW1
CNXpCd0tUV0ZZdGtzR1J4cFExcnBFR2VIaEwKNDFlZ3U0dUFCMzdLalZuakFqcDRSaTRLeXkxd0tSYm94REhIRS9EZDU
xTC9oK0FqZnVieTRWeGlPdmV3cG9pTgozN0gwTzlSTU5TRVJSelZDUlY0bUNBNStNRmZjdTRqbGh5ZFIyL2tXMFFLQmd
RRGZDQUtTUWlkcjEwTmI4eFlMCmlZTG9yeHZWUVFVV2hqaHA4T2FMMHVZdVBtSDlYSGRFbExpVHJNVHJ3c2dzY0xJYmI
zeUhWb0RkZitQdGdoRTUKM0JqYlArb1N6Z3lBdzZTVkF5V1pmK1hJc2htRXpoeGE0YjRXZFdTVFZqdWJSaTNXaHo4Unh
VNUZpRjBUWThSbApzcEpkc2NML1NLTlpkRG1YemFOZFhJRi9lUUtCZ1FERWxjL2JHQ1BMTDhwOHVvb3UvNVBIL3hyUnp
xamNwTk1QCjVyTHZVWG05Zm9BQ2FYUW9qeFVZWUFWTHg0NUQ4SmFBRkZ2eWo3QktmMjFKUlA2UmtyNkt4bDZCR0NWZzh
NZFoKVnFMbUNLSm5CTWtub01uL2gvVHN3U0RLc3dkTDV1dHlFZW5handYdU1TNStLcEFxQ05YZUYyZWZLdUR5RGpabwp
TQnRJWk93bGZ3S0JnQ2hvcVI2YXdjMjJLTXdtRk41dVFidXF6bDRxODZNTEdubUhYaDREVGhVZTFGNDFQSjYrClcxcld
PSytHa1FJTVFSamJjREtOSjdmNkRlL2dXd0tpc0JZNVZFMm42M2dBK25iTkpTalVsV3R4YVZJNGpjYWgKay9FNXY4azZ
ISDZiam42SjdwRm5zRERFN2lndk1VeER1R1VsYWZFYTFnM0ZFeEkvL2ROU2lWdnhBb0dBZk43ZwppajRhTm0ya0JIaUZ
YL2N1U3dMTGFXd0pMU1p4Znc4K3ArK3NFZWN4cnRhSFYyQVR1VWo4OURiTk1uQzNXS0lXCkNjeWw0Wnh4c2ZIbWRsZXR
PdjlvZGozOU9FZVppVEVVQm1TRHJFYUk4K2daaDN3MzRzK0dZZHM0amtrZlUzVDMKZzhJSHRWWitQdHdRZVBMbHpzNDF
IeENnbElEY3BIN3cvWnBGdWtjQ2dZQmppRWU4Zm1HLytJeUNhMEdYVnM0WQpQNm5lVGxUTlArWklMRzFFZTVtNFlDanN
3VmIrQzZHZms3TG02VWYvYnRlR0VJWVpnWURSTE9vSXlhenVmMFR2CjlSNm94TmV5MTUzOWoxS2ZpY3VxcW94cVBraks
rUnd3cUQ3Zm4wTGFJQ3JRUE1DTk9xbUpPbGxuNVBUR0E0N1MKRWgybm1YKzcvZUZBUW5malhNMU5WQT09Ci0tLS0tRU5
EIFBSSVZBVEUgS0VZLS0tLS0=`.split("\n").join("")));
const pss = forge.pss.create({
   md: forge.md.sha256.create(),
   mgf: forge.mgf.mgf1.create(forge.md.sha256.create()),
   saltLength: 32
});

let lastIssuedTime = +new Date();
let lastToken = null;
let lastUser = null;

export default function generateSpinellaToken(user, ts) {
   if (lastToken !== null && (lastUser !== null && lastUser === user) && +new Date() - lastIssuedTime < 1000) {
      return lastToken;
   }

   const sid = "hpacs";
   lastIssuedTime = ts ?? +new Date();
   // FIXME: user 가 string 으로 들어올 때가 있어서 user.id ?? user 로 해둠. user.id 가 맞는 표현임
   const data = `${user.id ?? user}|${user.email}|${lastIssuedTime}`;
   const encrypted = spinellaPubKey.encrypt(data, "RSA-OAEP", {
      md: forge.md.sha256.create(),
      mgf1: {
         md: forge.md.sha256.create()
      }
   });
   const hash = forge.md.sha256.create().update(data, "utf8").digest().data;
   const sign = ourPrivKey.sign(forge.md.sha256.create().update(hash, "raw"), pss);
   lastToken = `${btoa(sid)}.${btoa(encrypted)}.${btoa(hash)}.${btoa(sign)}`;
   lastUser = user;
   return lastToken;
}

window.generateSpinellaToken = generateSpinellaToken;