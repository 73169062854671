import { Page, SavedReadingPhrase } from "spinel";
import generateSpinellaToken from "../utils/spinella-token";

export interface ReadingPhraseShortcut {
   id: string;
   mnemonics: string;
   phrase: SavedReadingPhrase;
}
export interface SpreadReadingPhraseShortcut extends SavedReadingPhrase {
   shortcutId: string;
   mnemonics: string;
}

export interface DicomSrMetaInformation {
   "(0008,0060)": string; // Modality
   "(0010,0020)": string; // Patient ID
   "(0008,0050)"?: string; // Accession Number
   "(0040,A030)"?: string; // Verifying Observer Name
   "(0040,A027)"?: string; // Verifying Organization
   "(0040,A493)": string; // Verification Flag
   "(0010,1010)"?: string; // Patient's Age
   "(0010,0030)"?: string; // Patient's Birth Date
   "(0010,0010)"?: string; // Patient's Name
   "(0010,0040)"?: string; // Patient's Sex
   "(0040,A491)": string; // Completion Flag
   "(0008,1030)"?: string; // Study Description
   "(0008,0020)"?: string; // Study Date
   "(0008,0030)"?: string; // Study Time
   "(0008,0018)"?: string; // SOP Instance UID
   "(0020,0013)": string; // Instance Number
   "(0020,0011)": string; // Series Number

   "(0002,0003)"?: string; // Media Storage Instance UID
   "(0002,0012)"?: string; // Implementation Class UID
   "(0020,000D)": string; // Study Instance UID
   "(0020,000E)"?: string; // Series Instance UID
}

export interface ReadingPhraseMetaDicomSr {
   type: "DICOM_SR",
   identifiers: {
      system: string,
      value: string,
   }[],
   meta: DicomSrMetaInformation,
}

// TODO: move to environment file
const BASE_URL = "https://spinella.healthhub.dev";

async function getReadingPhrase(phraseId: string): Promise<SavedReadingPhrase> {
   const response = await fetch(`${BASE_URL}/phrase/${phraseId}`, {
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
      },
   });
   if (!response.ok) {
      console.debug(new Error(`${response.status} ${response.statusText}`));
      throw new Error(`${response.status} ${response.statusText}`);
   }
   return response.json();
}

async function getReadingPhrases({ page = 0, size = 10, sort, sortdir, title, modality, bodypart }): Promise<Page<SavedReadingPhrase>> {
   const response = await fetch(`${BASE_URL}/phrase?page=${page}&size=${size}&title=${title}&sort=${sort}&sortdir=${sortdir}&modality=${modality.join(",")}&bodypart=${bodypart.join(",")}`, {
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
      },
   });
   if (!response.ok) {
      console.debug(new Error(`${response.status} ${response.statusText}`));
      await response.json();
      throw new Error(`${response.status} ${response.statusText}`);
   }
   return response.json();
}

async function getBookmarksReadingPhrases(): Promise<ReadingPhraseShortcut[]> {
   const response = await fetch(`${BASE_URL}/myphrase`, {
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
      },
   });
   if (!response.ok) {
      console.debug(new Error(`${response.status} ${response.statusText}`));
      await response.json();
      throw new Error(`${response.status} ${response.statusText}`);
   }
   return response.json();
}

async function requestRegisterReadingPhraseAsMyPhrase(phraseId: string, mnemonics: string) {
   if (!phraseId) {
      throw new Error("The query parameter 'phraseId' is required");
   }
   const response = await fetch(`${BASE_URL}/myphrase?phraseId=${phraseId}&mnemonics=${mnemonics}`, {
      method: "POST",
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
      },
   });
   return response;
}

async function requestDeregisterReadingPhraseAsMyPhrase(phraseId: string) {
   if (!phraseId) {
      throw new Error("The path parameter 'phraseId' is required");
   }
   const response = await fetch(`${BASE_URL}/myphrase/${phraseId}`, {
      method: "DELETE",
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
      },
   });
   return response.json();
}

async function requestChangeShortcut(bookmarkId: string, mnemonics = "") {
   if (!bookmarkId) {
      throw new Error("The path parameter 'bookmarkId' is required");
   }
   const response = await fetch(`${BASE_URL}/myphrase/${bookmarkId}?mnemonics=${mnemonics}`, {
      method: "PUT",
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
      },
   });
   return response.json();
}

async function saveReadingPhrases(metaInformation: ReadingPhraseMetaDicomSr, readingPhraseRecords: SavedReadingPhrase[]) {
   return fetch(`${BASE_URL}/bundle`, {
      method: "POST",
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ metaInformation, readingPhraseRecords }),
   });
}

async function getSavedSnowphraseRecord(studyId: string, system = "hpacs") {
   const readingPhraseBundles = await fetch(
      `${BASE_URL}/bundle?system=${system}&value=${studyId}`,
      {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
         },
      },
   ).then(response => response.json());
   return readingPhraseBundles[readingPhraseBundles.length - 1]; // TODO: 하나가 아닌 경우 제일 최신 것을 가져와야 함
}

export interface MetainformationSchema {
   id: string;
   schemaId: string;
   name: string;
   schema: { [key: string]: string[] };
}
async function getSchemaList(): Promise<MetainformationSchema[]> {
   return fetch(`${BASE_URL}/metainformation`, {
      method: "GET",
      headers: {
         "Authorization": generateSpinellaToken(JSON.parse(localStorage.user)),
         "Content-Type": "application/json",
      },
   }).then(response => response.json());
}

export default {
   getReadingPhrase,
   getReadingPhrases,
   getBookmarksReadingPhrases,
   requestRegisterReadingPhraseAsMyPhrase,
   requestDeregisterReadingPhraseAsMyPhrase,
   requestChangeShortcut,
   saveReadingPhrases,
   getSavedSnowphraseRecord,
   getSchemaList,
};
