import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tab as _Tab, tabClasses, Tabs, tabsClasses } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import {
   ColDef,
   ColGroupDef,
   GetContextMenuItems,
   GridOptions,
   IServerSideDatasource,
   ServerSideStoreType,
} from "ag-grid-community";
import { SavedReadingPhrase } from "spinel";
import i18n from "../../../utils/i18n-utils";
import GridUtils from "../../../utils/grid-utils";
import generateSpinellaToken from "../../../utils/spinella-token";
import SnowPhraseAPI, { MetainformationSchema, ReadingPhraseShortcut, SpreadReadingPhraseShortcut } from "../../../api/SnowPhraseAPI";
import { useToastDispatch } from "../../../context/ToastContext";

const Container = styled(Box)`
   width: 100%;
   height: 100%;
`;

const Template = styled(Box)`
   height: 100%;
   display: flex;
   flex-direction: column;
`;

const TemplateList = styled(Box)`
   flex: 1;
`;

const Tab = styled(_Tab)(() => ({
   color: "#aaaaaa",
}));

enum SelectedTab {
   Bookmarks = 0,
   All = 1,
}
interface ReadingPhraseListProps {
   onSelectReadingPhrase?(phrase: SavedReadingPhrase): void;
}
export const ReadingPhraseList = (props: ReadingPhraseListProps) => {
   // Redux

   // Context

   // Style and Ref
   const tabStyle = {
      [tabClasses.root]: "#aaaaaa",
   };
   const dialogRef = useRef<HTMLDialogElement>(null);

   // state
   const [selectedTabIndex, setSelectedTabIndex] = useState<SelectedTab>(SelectedTab.Bookmarks);
   const [myPhrases, setMyPhrases] = useState();
   const [mnemonics, setMnemonics] = useState("");
   const [id, setId] = useState("");
   const { onSelectReadingPhrase: _onSelectReadingPhrase } = props;
   const mnemonicsRef = useRef(mnemonics);
   mnemonicsRef.current = mnemonics;

   // tag
   const [memoSchema, setMemoSchema] = useState<MetainformationSchema>();
   useEffect(() => {
      SnowPhraseAPI.getSchemaList()
         .then(list =>
            setMemoSchema(list.find(schema => schema.name === "Radiology")));
   }, []);

   const [modality, setModality] = useState<string[]>([]);
   const [bodypart, setBodypart] = useState<string[]>([]);
   const onChangeModality = (event: SelectChangeEvent<string[]>) => {
      const {
         target: { value },
      } = event;
      setModality(typeof value === "string" ? value.split(",") : value);
   };
   const onChangeBodypart = (event: SelectChangeEvent<string[]>) => {
      const {
         target: { value },
      } = event;
      setBodypart(typeof value === "string" ? value.split(",") : value);
   };

   // variable

   // grid
   const gridOptions: GridOptions = {
      defaultColDef: {
         sortable: true,
         resizable: true,
         floatingFilter: true,
         suppressMenu: true,
         filterParams: {
            newRowsAction: "keep",
         },
      },
      rowModelType: "serverSide",
      serverSideStoreType: ServerSideStoreType.Partial,
      cacheBlockSize: 10,
      rowSelection: "single",
      overlayLoadingTemplate: `<span style='font-size: 13px'>${i18n("label.noRecordsFound")}</span>`,
      overlayNoRowsTemplate: `<span style='font-size: 13px'>${i18n("label.noRecordsFound")}</span>`,
      rowDragManaged: false,
      animateRows: false,
      suppressMovableColumns: true,
   };
   // https://stackoverflow.com/questions/43246606/aggrid-multiline-cell-content
   const columnDefs: (ColDef | ColGroupDef)[] = useMemo(() => [
      {
         headerName: i18n("label.shortcut"),
         field: "mnemonics",
         width: 90,
         pinned: "left",
         sortable: selectedTabIndex === SelectedTab.Bookmarks,
         filter: "agTextColumnFilter",
         filterParams: { buttons: ["clear", "apply"], caseSensitive:false },
         floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
         headerName: i18n("label.title"),
         field: "title",
         flex: 1,
         cellStyle: { "white-space": "normal" },
         autoHeight: true,
         filter: "agTextColumnFilter",
         filterParams: { buttons: ["clear", "apply"], caseSensitive:false },
         floatingFilterComponentParams: { suppressFilterButton: true },
      },
   ], [selectedTabIndex]);

   const [memoBookmarks, setMemoBookmarks] = useState<SpreadReadingPhraseShortcut[]>([]);
   const getBookmarks = useCallback(async () => SnowPhraseAPI.getBookmarksReadingPhrases().then(phrases => (
      phrases.map<SpreadReadingPhraseShortcut>(({ id, mnemonics, phrase }) => ({
         ...phrase,
         mnemonics,
         shortcutId: id,
      }))
   )), []);
   useEffect(() => {
      getBookmarks().then(setMemoBookmarks);
   }, []);

   const allPhrases: IServerSideDatasource = useMemo(() => ({
      getRows: async (params) => {
         const size = 10;
         const sort = params.request.sortModel[0]?.colId ?? "createdAt";
         const sortdir = params.request.sortModel[0]?.sort?.toUpperCase() ?? "DESC";
         const titleFilter = params.request.filterModel["title"]?.filter?.toLowerCase() ?? "";
         SnowPhraseAPI.getReadingPhrases({ page: (params.request.startRow || 0) / size, size, sort, sortdir, title: titleFilter, modality, bodypart }).then((page) => {
            const data = page.content
               .map(content => ({
                  ...content,
                  mnemonics: memoBookmarks.find(bookmark => bookmark.phraseId === content.phraseId)?.mnemonics,
               }));
            params.success({
               rowData: data,
               rowCount: page.totalElements,
            });
         });
      },
   }), [memoBookmarks, bodypart, modality]);

   const bookmarks: IServerSideDatasource = useMemo(() => ({
      getRows: async (params) => {
         function returnRow(bookmarks: SpreadReadingPhraseShortcut[]) {
            params.success({
               rowData: bookmarks.slice(params.request.startRow || 0, (params.request.startRow || 0) + 10),
               rowCount: bookmarks.length,
            });
         }
         const sort = params.request.sortModel[0]?.colId ?? "createdAt";
         const sortdir = params.request.sortModel[0]?.sort?.toUpperCase() ?? "DESC";
         const titleFilter = params.request.filterModel["title"]?.filter?.toLowerCase() ?? "";
         const shortcutFilter = params.request.filterModel["mnemonics"]?.filter?.toLowerCase() ?? "";
         const sortedAndFilteredBookmarks = memoBookmarks
            .filter(content =>
               (content.mnemonics?.toLowerCase() ?? "").includes(shortcutFilter)
               && (content.title?.toLowerCase() ?? "").includes(titleFilter))
            .filter(content => modality.includes(content.tags["modality"]) || !modality.length)
            .filter(content => bodypart.includes(content.tags["bodypart"]) || !bodypart.length)
            .sort((a, b) => {
               const sortMult = sortdir === "DESC" ? -1 : 1;
               const aField = a[sort];
               const bField = b[sort];
               if (aField && bField && typeof aField === "string" && typeof bField === "string") {
                  return aField.localeCompare(bField) * sortMult;
               }
               if (!aField?.length && !bField?.length) {
                  return 0;
               }
               return aField?.length ? -1 : 1;
            });
         returnRow(sortedAndFilteredBookmarks);
      },
   }), [memoBookmarks, bodypart, modality]);

   const serverSideDatasource: IServerSideDatasource = useMemo(() => (
      selectedTabIndex === SelectedTab.Bookmarks ? bookmarks : allPhrases
   ), [selectedTabIndex, bookmarks, allPhrases]);

   const onSelectReadingPhrase = async (phrase: SavedReadingPhrase | SpreadReadingPhraseShortcut) => {
      if (selectedTabIndex === SelectedTab.Bookmarks) {
         const readingPhrase = await SnowPhraseAPI.getReadingPhrase(phrase.phraseId);
         _onSelectReadingPhrase?.(readingPhrase);
      } else {
         _onSelectReadingPhrase?.(phrase as SavedReadingPhrase);
      }
   };

   const toastAction = useToastDispatch();
   const getAllPhrasesContextMenu: GetContextMenuItems = params => (
      [
         {
            name: i18n("label.editSnowPhrasePreset"),
            action: () => {
               localStorage.setItem("garnetPhraseId", params.node.data.phraseId);
               window.garnet = window.open(__GARNET_URL__, "garnet", "dependent=yes");
            },
         },
         {
            name: i18n("label.registerBookmark"),
            action: () => {
               const phrase: SavedReadingPhrase = params.node.data;
               // TODO: Modal 로 교체해야함
               const mnemonics = window.prompt("mnemonics", "") as string;
               SnowPhraseAPI.requestRegisterReadingPhraseAsMyPhrase(phrase.phraseId, mnemonics).then(async (resp) => {
                  if (resp.ok) {
                     toastAction({ type: "SET_TOAST", open: true, msg:i18n("msg.readingPhraseList.saveMnemonicsSuccess"), isErr: false });
                  } else {
                     const body = await resp.json();
                     if (resp.status === 409) {
                        if (body.code === "4092") {
                           toastAction({ type: "SET_TOAST", open: true, msg:i18n("msg.readingPhraseList.duplicatePhraseError"), isErr: true });
                        } else if (body.code === "4093") {
                           toastAction({ type: "SET_TOAST", open: true, msg:i18n("msg.readingPhraseList.duplicateMnemonicsError"), isErr: true });
                        } else {
                           toastAction({ type: "SET_TOAST", open: true, msg:i18n("msg.readingPhraseList.failed"), isErr: true });
                        }
                     } else {
                        toastAction({ type: "SET_TOAST", open: true, msg:i18n("msg.readingPhraseList.failed"), isErr: true });
                     }
                  }
               });
            },
         },
      ]
   );
   const getBookmarkContextMenu: GetContextMenuItems = params => (
      [
         {
            name: i18n("label.editSnowPhrasePreset"),
            action: () => {
               localStorage.setItem("garnetPhraseId", params.node.data.phraseId);
               window.garnet = window.open(__GARNET_URL__, "garnet", "dependent=yes");
            },
         },
         {
            name: i18n("label.editMnemonics"),
            action: () => {
               if (!dialogRef.current) return;
               const dialog = dialogRef.current;
               const bookmark = params.node.data;
               setId(bookmark.shortcutId);
               setMnemonics("");
               dialog.showModal();

               const cleanup = () => {
                  dialog.returnValue = "";
                  setId("");
                  setMnemonics("");
                  dialog.removeEventListener("close", closeListener);
                  dialog.removeEventListener("cancel", cancel);
               };
               const cancel = () => {
                  cleanup();
               };

               const closeListener = () => {
                  const bookmarkId = dialog.returnValue;
                  if (!bookmarkId) {
                     cleanup();
                     return;
                  }
                  SnowPhraseAPI.requestChangeShortcut(bookmarkId, mnemonicsRef.current.trim()).then(({ phrase, mnemonics, id }) => {
                     params.node.setData({
                        ...phrase,
                        mnemonics,
                        shortcutId: id,
                     });
                  }).finally(() => {
                     cleanup();
                  });
               };
               dialog.addEventListener("close", closeListener);
               dialog.addEventListener("cancel", cancel);
            },
         },
         {
            name: i18n("label.deregisterBookmark"),
            action: () => {
               const bookmark: SpreadReadingPhraseShortcut = params.node.data;
               SnowPhraseAPI.requestDeregisterReadingPhraseAsMyPhrase(bookmark.shortcutId).then(() => {
                  // show success message
                  // params.rowIndex
                  // invalidate bookmarks cache to reload

                  // params.api?.refreshServerSide();
               }).catch(() => {
                  // show error message
               });
            },
         },
      ]
   );

   const getContextMenuItems: GetContextMenuItems = (params) => {
      if (!params.node) return [];

      // 마우스 우클릭으로 row가 선택되게끔 추가
      params.node.setSelected(true, true);

      if (selectedTabIndex === SelectedTab.Bookmarks) {
         return getBookmarkContextMenu(params);
      }
      return getAllPhrasesContextMenu(params);
   };

   return (
      <Container>
         <Template>
            <Tabs
               value={selectedTabIndex}
               onChange={(event, newTab: number) => setSelectedTabIndex(newTab)}
               aria-label="basic tabs example"
               variant="scrollable"
               scrollButtons>
               {/* TODO: label 을 i18n 으로 빼기 */}
               <Tab label="Bookmarks" sx={tabStyle} />
               <Tab label="All" sx={tabStyle} />
            </Tabs>
            <FormControl style={{ marginTop: 5 }}>
               <InputLabel shrink>Modality</InputLabel>
               {!!memoSchema?.schema["modality"].length
                  && <Select
                     multiple
                     size="small"
                     value={modality}
                     onChange={onChangeModality}
                     input={<OutlinedInput label="Modality" />}
                     renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                           {selected.map(value => (
                              <Chip key={value} label={value} style={{ color: "white" }} />
                           ))}
                        </Box>
                     )}
                  >
                     {memoSchema.schema["modality"].map(name => (
                        <MenuItem
                           key={name}
                           value={name}
                        >
                           {name}
                        </MenuItem>
                     ))}
                  </Select>
               }
            </FormControl>
            <FormControl style={{ marginTop: 5 }}>
               <InputLabel shrink>Bodypart</InputLabel>
               {!!memoSchema?.schema["bodypart"].length
                  && <Select
                     multiple
                     size="small"
                     value={bodypart}
                     onChange={onChangeBodypart}
                     input={<OutlinedInput label="Bodypart" />}
                     renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                           {selected.map(value => (
                              <Chip key={value} label={value} style={{ color: "white" }} />
                           ))}
                        </Box>
                     )}
                  >
                     {memoSchema.schema["bodypart"].map(name => (
                        <MenuItem
                           key={name}
                           value={name}
                        >
                           {name}
                        </MenuItem>
                     ))}
                  </Select>
               }
            </FormControl>
            <TemplateList className="ag-theme-balham-dark">
               <AgGridReact
                  containerStyle={{
                     "fontSize": "11px",
                  }}
                  rowStyle={{
                     "paddingLeft": "2px",
                  }}
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  serverSideDatasource={serverSideDatasource}
                  onCellDoubleClicked={e => onSelectReadingPhrase(e.data)}
                  onCellKeyDown={(e) => {
                     if ((e.event as KeyboardEvent).key === "Enter") {
                        onSelectReadingPhrase(e.data);
                     }
                  }}
                  getContextMenuItems={getContextMenuItems}
               />
            </TemplateList>
            <dialog id="mnemonicsFormDialog" ref={dialogRef}>
               <form method="dialog">
                  <div>Mnemonics Edit Modal</div>
                  <label>
                     <span>Mnemonics: </span>
                     <input id="mnemonicsFormDialogInput" value={mnemonics} onInput={(e) => {
                        setMnemonics(e.currentTarget.value);
                     }} />
                  </label>
                  <button id="mnemonicsFormDialogConfirmButton" value={id}>Confirm</button>
                  <button value="cancel">Cancel</button>
               </form>
            </dialog>
         </Template>
      </Container>
   );
};
